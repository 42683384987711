import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faMapMarkerAlt,
  faMobileAlt,
  faIdCard,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

export const loadIcons = (
  icons = [fab, faEnvelope, faMapMarkerAlt, faMobileAlt, faIdCard, faChevronUp],
) => {
  library.add(...icons);
};
